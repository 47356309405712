@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    ::-moz-selection{
      background-color: #AAF0D1;
      }
    ::selection{
      background-color: #AAF0D1;
      }  

    ::-webkit-scrollbar {
        width: 12px !important;
     }
     /* Track */
    ::-webkit-scrollbar-track {
       -webkit-border-radius: 10px !important;
       border-radius: 10px !important;
     }
    
     /* Handle */
     ::-webkit-scrollbar-thumb {
       -webkit-border-radius: 10px !important;
       border-radius: 10px !important;
       background: #AAF0D1 !important; 
       -webkit-box-shadow: inset 0 0 3px rgba(90, 90, 90, 0.3) !important; 
    
     }
     ::-webkit-scrollbar-thumb:window-inactive {
       background: #AAF0D1 !important; 
     }
}

.icon-transition {
  transition: transform 0.3s ease;
}
