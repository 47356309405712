h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
.section {
  width: 90vw;
  margin: 3rem auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;
  margin-top: 3rem;
  width: 80vw;
  height: 320px;
  max-width: 1000px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

@media screen and (max-width: 900px) {
  .section-center {
    height: 390px;
  }
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h3 {
  text-transform: uppercase;
  color: hsl(21, 62%, 45%);
  margin-bottom: 0.25rem;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: hsl(209, 34%, 30%);
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 1rem;
  line-height: 2;
  color: hsl(210, 22%, 49%);
}

.prev,
.next {
  position: absolute;
  top: 200px;
  transform: translateY(-50%);
  background: hsl(210, 22%, 49%);
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev {
  top: 170px;
  left: 20px;
}
.next {
  top: 170px;
  right: 20px;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
