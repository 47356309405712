.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
}